import React from 'react';
import HeroRankTracker from '../components/pages/RankTracker/HeroRankTracker';
// import WhySpyserp from '../components/pages/RankTracker/WhySpyserp';
import Pricing from '../components/Pricing';
import FeaturesSlider from '../components/pages/RankTracker/FeaturesSlider';
import MetaTags from '../components/Seo/MetaTags';
import MultifunctionalRankTracker from '../components/pages/RankTracker/MultifunctionalRankTracker';
import CompetitorsTracking from '../components/pages/RankTracker/CompetitorsTracking';
import DetailedAnalytics from '../components/pages/RankTracker/DetailedAnalytics';
import KeywordsClustering from '../components/pages/RankTracker/KeywordsClustering';
import DashboardFeatures from '../components/pages/RankTracker/DashboardFeatures';
import FreeSERPChecker from '../components/pages/RankTracker/FreeSERPChecker';
import Features from '../components/pages/RankTracker/Features';
import RankTrackerFAQ from '../components/pages/RankTracker/RankTrackerFAQ';
import Brands from '../components/Brands';
import { useTranslation } from 'react-i18next';
import StructuredService from '../components/Seo/StructuredService';
import StructuredAggregateRating from '../components/Seo/StructuredAggregateRating';
import Trustpilot from '../components/Trustpilot';
import LatestBlogPosts from '../components/pages/RankTracker/LatestBlogPosts';

const IndexPage = () => {
  const { t } = useTranslation('RankTracker');
  const title = t('seo.title');
  const description = t('seo.description');

  return (
    <>
      <MetaTags title={title} description={description} />
      <StructuredService name="rank tracker" />
      <StructuredAggregateRating
        name="SpySERP rank tracker tool"
        description="The SpySERP Rank Tracker Tool is our flagship SEO product, giving both experienced and novice SEO users the inside track on their web page performance across multiple search engines."
        makesOffer="rank tracker"
        knowsAbout="rank tracker tool"
        ratingValue={5}
        bestRating={5}
        ratingCount={24}
      />
      <HeroRankTracker />
      {/*<WhySpyserp />*/}
      <Trustpilot />
      <Brands />
      <Pricing />
      <MultifunctionalRankTracker />
      <CompetitorsTracking />
      <DetailedAnalytics />
      <KeywordsClustering />
      <DashboardFeatures />
      <FreeSERPChecker />
      <Features />
      <LatestBlogPosts />
      <RankTrackerFAQ />
      <FeaturesSlider />
    </>
  );
};

export default IndexPage;
