import React from 'react';
import ContentSection from '../../ContentSection';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap';
import TasksIcon from '../../../assets/icons/font-awesome/solid/tasks.svg';
import ShareIcon from '../../../assets/icons/font-awesome/solid/share-alt.svg';
import UsersIcon from '../../../assets/icons/font-awesome/solid/users.svg';
import ChartLineIcon from '../../../assets/icons/font-awesome/solid/chart-line.svg';
import ChartBarIcon from '../../../assets/icons/font-awesome/solid/chart-bar.svg';
import NetworkIcon from '../../../assets/icons/font-awesome/solid/network-wired.svg';
import GroupIcon from '../../../assets/icons/font-awesome/regular/object-group.svg';
import UserSecretIcon from '../../../assets/icons/font-awesome/solid/user-secret.svg';
import CodeIcon from '../../../assets/icons/font-awesome/solid/code.svg';

function Features() {
  const { t } = useTranslation('RankTracker');
  const title = t('Features.title');

  const items = [
    {
      icon: TasksIcon,
      title: t('Features.feature1.title'),
      text: t('Features.feature1.text'),
    },
    {
      icon: ShareIcon,
      title: t('Features.feature2.title'),
      text: t('Features.feature2.text'),
    },
    {
      icon: UsersIcon,
      title: t('Features.feature3.title'),
      text: t('Features.feature3.text'),
    },
    {
      icon: ChartLineIcon,
      title: t('Features.feature4.title'),
      text: t('Features.feature4.text'),
    },
    {
      icon: ChartBarIcon,
      title: t('Features.feature5.title'),
      text: t('Features.feature5.text'),
    },
    {
      icon: NetworkIcon,
      title: t('Features.feature6.title'),
      text: t('Features.feature6.text'),
    },
    {
      icon: GroupIcon,
      title: t('Features.feature7.title'),
      text: t('Features.feature7.text'),
    },
    {
      icon: UserSecretIcon,
      title: t('Features.feature8.title'),
      text: t('Features.feature8.text'),
    },
    {
      icon: CodeIcon,
      title: t('Features.feature9.title'),
      text: t('Features.feature9.text'),
    },
  ];

  return (
    <ContentSection id="features" title={title}>
      <Row>
        {items.map((item, index) => {
          return (
            <Col md="4" key={`keywords-clustering-${index}`}>
              <div className="d-flex pb-5">
                <span className="svg-color-primary mr-6">
                  <item.icon width="24px" />
                </span>

                <div>
                  <h3 className="gr-text-8 font-weight-bold mb-0">
                    {item.title}
                  </h3>
                  <p className="mt-5">{item.text}</p>
                </div>
              </div>
            </Col>
          );
        })}
      </Row>
    </ContentSection>
  );
}

export default Features;
