import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import { Container, Row, Col } from 'react-bootstrap';

const slickSettings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToScroll: 1,
  arrows: false,
  className: 'single-slide',
  responsive: [
    {
      breakpoint: 2400,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

const FeaturesSlider = () => {
  const elSlider = useRef();
  const { t } = useTranslation('RankTracker');
  const title = t('FeaturesSlider.title');
  const subtitle = t('FeaturesSlider.subtitle');
  const prevBtnText = t('FeaturesSlider.prev');
  const nextBtnText = t('FeaturesSlider.next');

  const items = [
    {
      title: t('FeaturesSlider.item1.title'),
      description: t('FeaturesSlider.item1.description'),
      descriptionItems: [
        t('FeaturesSlider.item1.descriptionItem1'),
        t('FeaturesSlider.item1.descriptionItem2'),
        t('FeaturesSlider.item1.descriptionItem3'),
        t('FeaturesSlider.item1.descriptionItem4'),
      ],
    },
    {
      title: t('FeaturesSlider.item2.title'),
      description: t('FeaturesSlider.item2.description'),
      descriptionItems: [
        t('FeaturesSlider.item2.descriptionItem1'),
        t('FeaturesSlider.item2.descriptionItem2'),
        t('FeaturesSlider.item2.descriptionItem3'),
        t('FeaturesSlider.item2.descriptionItem4'),
      ],
      descriptionAfter: t('FeaturesSlider.item2.descriptionAfter'),
    },
    {
      title: t('FeaturesSlider.item3.title'),
      description: t('FeaturesSlider.item3.description'),
    },
    {
      title: t('FeaturesSlider.item4.title'),
      description: t('FeaturesSlider.item4.description'),
    },
    {
      title: t('FeaturesSlider.item5.title'),
      description: t('FeaturesSlider.item5.description'),
    },
    {
      title: t('FeaturesSlider.item6.title'),
      description: t('FeaturesSlider.item6.description'),
    },
    {
      title: t('FeaturesSlider.item7.title'),
      description: t('FeaturesSlider.item7.description'),
    },
    {
      title: t('FeaturesSlider.item8.title'),
      description: t('FeaturesSlider.item8.description'),
    },
    {
      title: t('FeaturesSlider.item9.title'),
      description: t('FeaturesSlider.item9.description'),
    },
    {
      title: t('FeaturesSlider.item10.title'),
      description: t('FeaturesSlider.item10.description'),
    },
    {
      title: t('FeaturesSlider.item11.title'),
      description: t('FeaturesSlider.item11.description'),
    },
    {
      title: t('FeaturesSlider.item12.title'),
      description: t('FeaturesSlider.item12.description'),
    },
    {
      title: t('FeaturesSlider.item13.title'),
      description: t('FeaturesSlider.item13.description'),
    },
    {
      title: t('FeaturesSlider.item14.title'),
      description: t('FeaturesSlider.item14.description'),
    },
  ];

  return (
    <div className="job-site-page feature-section bg-default-2 bg-pattern pattern-5 pb-2 pb-lg-22">
      <Container>
        <Row className="justify-content-center">
          <Col xl="6" lg="7" md="8">
            <div className="section-title text-center mt-13 mb-13">
              <h4 className="pre-title gr-text-12 text-red text-uppercase mb-7">
                {title}
              </h4>

              <h2 className="title gr-text-4">{subtitle}</h2>
            </div>
          </Col>
        </Row>

        <Row className="justify-content-lg-between align-items-center pb-9 pb-sm-0">
          <Col>
            <div className="job-feature-slider-arrows ml-auto">
              <button
                className="slick-prev slick-arrow"
                aria-label={prevBtnText}
                type="button"
                onClick={() => {
                  elSlider.current.slickPrev();
                }}
              >
                {prevBtnText}
              </button>

              <button
                className="slick-next slick-arrow"
                aria-label={nextBtnText}
                type="button"
                onClick={() => {
                  elSlider.current.slickNext();
                }}
              >
                {nextBtnText}
              </button>
            </div>
          </Col>
        </Row>

        <div className="job-feature-slider">
          <Slider className="features-slider" ref={elSlider} {...slickSettings}>
            {items.map(
              (
                {
                  title,
                  description,
                  descriptionItems = false,
                  descriptionAfter = false,
                },
                index
              ) => (
                <div key={`slide-item-${index}`}>
                  <div
                    className="feature-widget justify-content-between h-100 focus-reset bg-white d-flex flex-column min-height-px-297
                      border rounded-10 pt-8 pb-8 pl-7 pr-7 mx-6 pl-lg-9 pr-md-8 pr-lg-10 gr-hover-shadow-1"
                  >
                    <div className="mb-auto">
                      <h3 className="gr-text-7 text-blackish-blue">{title}</h3>

                      <p className="gr-text-11 gr-color-blackish-blue-opacity-7 mb-0">
                        {description}
                      </p>

                      <div className="gr-text-11 gr-color-blackish-blue-opacity-7 mb-0">
                        {descriptionItems && (
                          <ul>
                            {descriptionItems.map((txt, itemIndex) => (
                              <li key={`description-item-${itemIndex}`}>
                                {txt}
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>

                      {descriptionAfter && (
                        <p className="gr-text-11 gr-color-blackish-blue-opacity-7 mb-0">
                          {descriptionAfter}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              )
            )}
          </Slider>
        </div>
      </Container>
    </div>
  );
};

export default FeaturesSlider;
