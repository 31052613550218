import React from 'react';
import { useTranslation } from 'react-i18next';
import ContentSection from '../ContentSection';

import TrustpilotImage from './images/trustpilot.svg';
import TrustpilotStarsImage from './images/trustpilot-stars.svg';

function Trustpilot() {
  const { t } = useTranslation();
  const rating = t('Trustpilot.rating');
  const reviews = t('Trustpilot.reviews');
  const starsAltText = t('Trustpilot.stars');

  return (
    <ContentSection>
      <div className="d-flex align-items-center justify-content-center flex-column flex-lg-row">
        <div className="text-blackish-blue gr-text-6 text-nowrap pr-lg-10 py-5 py-lg-0">
          {rating}
        </div>
        <img
          src={TrustpilotStarsImage}
          alt={starsAltText}
          height={32}
          width={170}
        />
        <div
          className="text-nowrap px-10 py-5 py-lg-0"
          style={{ fontSize: '18px' }}
        >
          <a
            href="https://www.trustpilot.com/review/spyserp.com"
            target="_blank"
            rel="nofollow noreferrer noopener"
            title="Trustpilot"
          >
            {reviews}
          </a>
        </div>
        <img src={TrustpilotImage} alt="Trustpilot" height={36} width={146} />
      </div>
    </ContentSection>
  );
}

export default Trustpilot;
