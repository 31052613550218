import Hero from '../../Hero';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StaticImage } from 'gatsby-plugin-image';

function HeroKeywordTracker() {
  const { t } = useTranslation('RankTracker');
  const title = t('HeroRankTracker.title');
  const subtitle = t('HeroRankTracker.subtitle');
  const description = t('HeroRankTracker.description');

  return (
    <Hero
      title={title}
      subtitle={subtitle}
      description={description}
      //linkTo="why-to-choose"
    >
      <div
        className="mt-10 px-md-10"
        itemScope={true}
        itemType="https://schema.org/ImageObject"
      >
        <meta itemProp="name" content={title} />
        <meta itemProp="description" content={description} />
        <StaticImage
          src="../../../assets/image/rank-tracker/spyserp.jpg"
          width={1040}
          sizes="(min-width: 1040px) 1040px, (min-width: 360px) 330px, 100vw"
          breakpoints={[330, 635, 990, 1040, 1915]}
          loading="eager"
          placeholder="blurred"
          alt={title}
          className="shadow-1"
          itemProp="contentUrl"
        />
      </div>
    </Hero>
  );
}

export default HeroKeywordTracker;
