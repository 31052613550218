import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';
import ContentSection from '../../ContentSection';
import { Col, Row } from 'react-bootstrap';
import PostItem from '../../Blog/PostItem';

export default function LatestBlogPosts() {
  const { t, i18n } = useTranslation('RankTracker');
  const { language } = i18n;

  const data = useStaticQuery(graphql`
    query BlogQuery {
      latestPostsEn: allCraftBlogBlogEntry(
        sort: { fields: postDate, order: DESC }
        limit: 3
        skip: 0
        filter: { siteId: { eq: 2 } }
      ) {
        nodes {
          id
          title
          slug
          author {
            name
            remoteId
          }
          postDate
          featuredImage {
            id
            url
            title
            ... on Craft_imagesEn_Asset {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 350
                    aspectRatio: 3.68
                    transformOptions: { cropFocus: CENTER }
                  )
                }
              }
            }
            ... on Craft_imagesRu_Asset {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 350
                    aspectRatio: 3.68
                    transformOptions: { cropFocus: CENTER }
                  )
                }
              }
            }
          }
          featuredImageRetina {
            id
            url
            title
            ... on Craft_imagesEn_Asset {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 350
                    aspectRatio: 3.68
                    transformOptions: { cropFocus: CENTER }
                  )
                }
              }
            }
            ... on Craft_imagesRu_Asset {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 350
                    aspectRatio: 3.68
                    transformOptions: { cropFocus: CENTER }
                  )
                }
              }
            }
          }
          category {
            title
            id
            slug
          }
        }
      }

      latestPostsRu: allCraftBlogBlogEntry(
        sort: { fields: postDate, order: DESC }
        limit: 3
        skip: 0
        filter: { siteId: { eq: 1 } }
      ) {
        nodes {
          id
          title
          slug
          author {
            name
            remoteId
          }
          postDate
          featuredImage {
            id
            url
            title
            ... on Craft_imagesEn_Asset {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 350
                    aspectRatio: 3.68
                    transformOptions: { cropFocus: CENTER }
                  )
                }
              }
            }
            ... on Craft_imagesRu_Asset {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 350
                    aspectRatio: 3.68
                    transformOptions: { cropFocus: CENTER }
                  )
                }
              }
            }
          }
          featuredImageRetina {
            id
            url
            title
            ... on Craft_imagesEn_Asset {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 350
                    aspectRatio: 3.68
                    transformOptions: { cropFocus: CENTER }
                  )
                }
              }
            }
            ... on Craft_imagesRu_Asset {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 350
                    aspectRatio: 3.68
                    transformOptions: { cropFocus: CENTER }
                  )
                }
              }
            }
          }
          category {
            title
            id
            slug
          }
        }
      }
    }
  `);

  const posts =
    language === 'ru'
      ? data['latestPostsRu'].nodes
      : data['latestPostsEn'].nodes;

  const title = t('LatestBlogPosts.title');

  return (
    <ContentSection id="blog-posts" title={title} background={2}>
      <Row>
        {posts.map((item) => {
          const image =
            item.featuredImageRetina?.[0] || item.featuredImage?.[0];

          return (
            <Col md="6" lg="4" key={item.slug} className="mb-5">
              <PostItem image={image} {...item} />
            </Col>
          );
        })}
      </Row>
    </ContentSection>
  );
}
