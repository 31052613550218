import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { StaticImage } from 'gatsby-plugin-image';
import ContentSection from '../../ContentSection';
import { useTranslation } from 'react-i18next';
import CheckIcon from '../../../assets/icons/font-awesome/solid/check.svg';
import Link from '../../../i18n/Link';
import LongArrowIcon from '../../../assets/icons/font-awesome/regular/long-arrow-right.svg';

function KeywordsClustering() {
  const { t, i18n } = useTranslation('RankTracker');
  const { language } = i18n;
  const title = t('KeywordsClustering.title');
  const description = t('KeywordsClustering.description');
  const items = [
    t('KeywordsClustering.feature1'),
    t('KeywordsClustering.feature2'),
    t('KeywordsClustering.feature3'),
    t('KeywordsClustering.feature4'),
  ];

  return (
    <ContentSection background={2}>
      <Row
        className="align-items-center"
        itemScope={true}
        itemType="https://schema.org/ImageObject"
      >
        <Col lg="7" className="order-lg-last">
          <StaticImage
            src="../../../assets/image/rank-tracker/main4_web.png"
            width={635}
            sizes="(min-width: 635px) 635px, (min-width: 360px) 330px, 100vw"
            breakpoints={[330, 635, 990, 1270]}
            alt={title}
            placeholder="blurred"
            className="shadow-1"
            itemProp="contentUrl"
          />
        </Col>

        <Col lg="5" data-aos="fade-right" data-aos-duration="700">
          <h2 className="title gr-text-4 mb-7 mt-7" itemProp="name">
            {title}
          </h2>

          <p className="gr-text-8" itemProp="description">
            {description}
          </p>

          <Row>
            {items.map((item, index) => {
              return (
                <Col md="6" key={`keywords-clustering-${index}`}>
                  <div className="d-flex pb-5">
                    <span className="svg-color-primary mr-6">
                      <CheckIcon width="16px" />
                    </span>

                    <h3 className="gr-text-8 font-weight-bold mb-0">{item}</h3>
                  </div>
                </Col>
              );
            })}
          </Row>

          {language === 'en' && (
            <Link
              className="btn btn-primary with-icon py-2 px-5 mt-3"
              to="/keyword-grouping/"
            >
              {t('KeywordsClustering.linkTitle')}
              <span className="fa-svg-icon svg-color-white ml-5">
                <LongArrowIcon />
              </span>
            </Link>
          )}
        </Col>
      </Row>
    </ContentSection>
  );
}

export default KeywordsClustering;
