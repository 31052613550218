import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { StaticImage } from 'gatsby-plugin-image';
import ContentSection from '../../ContentSection';
import { useTranslation } from 'react-i18next';
import CheckIcon from '../../../assets/icons/font-awesome/solid/check.svg';

function CompetitorsTracking() {
  const { t } = useTranslation('RankTracker');
  const title = t('CompetitorsTracking.title');
  const description = t('CompetitorsTracking.description');
  const items = [
    t('CompetitorsTracking.feature1'),
    t('CompetitorsTracking.feature2'),
    t('CompetitorsTracking.feature3'),
    t('CompetitorsTracking.feature4'),
  ];

  return (
    <ContentSection background={2}>
      <Row
        className="align-items-center"
        itemScope={true}
        itemType="https://schema.org/ImageObject"
      >
        <Col lg="7" className="order-lg-last">
          <StaticImage
            src="../../../assets/image/rank-tracker/domain-comparison.jpg"
            width={635}
            sizes="(min-width: 635px) 635px, (min-width: 360px) 330px, 100vw"
            breakpoints={[330, 635, 990, 1270]}
            alt={title}
            placeholder="blurred"
            className="shadow-1"
            itemProp="contentUrl"
          />
        </Col>

        <Col lg="5" data-aos="fade-right" data-aos-duration="700">
          <h2 className="title gr-text-4 pr-lg-25 mb-7 mt-7" itemProp="name">
            {title}
          </h2>

          <p className="gr-text-8" itemProp="description">
            {description}
          </p>

          <Row>
            {items.map((item, index) => {
              return (
                <Col md="6" key={`competitors-tracker-${index}`}>
                  <div className="d-flex pb-5">
                    <span className="svg-color-primary mr-6">
                      <CheckIcon width="16px" />
                    </span>

                    <h3 className="gr-text-8 font-weight-bold mb-0">{item}</h3>
                  </div>
                </Col>
              );
            })}
          </Row>
        </Col>
      </Row>
    </ContentSection>
  );
}

export default CompetitorsTracking;
